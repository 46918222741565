import { Controller } from "@hotwired/stimulus";
const DISCOUNT_TYPE_PERCENTAGE = 1;
const DISCOUNT_TYPE_AMOUNT = 2;
const DISCOUNT_TYPE_FREE_SHIPPING = 5;
const HIDDEN_CLASS = "hidden";
export default class extends Controller {
  static targets = [
    "discounttype",
    "percentage",
    "amount",
    "shipping",
    "brandid",
    "tagid",
    "skus",
    "startat",
    "endat",
  ];
  connect() {}
  changeDiscountType(e) {
    const val = $(e.target).val();
    if (val) {
      switch (parseInt(val, 10)) {
        case DISCOUNT_TYPE_PERCENTAGE:
          $(this.percentageTarget).removeClass(HIDDEN_CLASS);
          $(this.amountTarget).addClass(HIDDEN_CLASS);
          $(this.shippingTarget).removeClass(HIDDEN_CLASS);
          break;
        case DISCOUNT_TYPE_AMOUNT:
          $(this.percentageTarget).addClass(HIDDEN_CLASS);
          $(this.amountTarget).removeClass(HIDDEN_CLASS);
          $(this.shippingTarget).removeClass(HIDDEN_CLASS);
          break;
        case DISCOUNT_TYPE_FREE_SHIPPING:
          $(this.percentageTarget).addClass(HIDDEN_CLASS);
          $(this.amountTarget).addClass(HIDDEN_CLASS);
          $(this.shippingTarget).addClass(HIDDEN_CLASS);
          break;
      }
    }
  }
  changeStartAt(e) {
    const val = $(e.target).val();
    if (val) {
      $(this.endatTarget).attr("min", val);
    }
  }
  changeEndAt(e) {
    const val = $(e.target).val();
    if (val) {
      $(this.startatTarget).attr("max", val);
    }
  }
  changeBrandId(e) {
    const val = $(e.target).val();
    if (val) {
      $(this.skusTarget).val("");
      $(this.tagidTarget).val("");
    }
  }
  changeTagId(e) {
    const val = $(e.target).val();
    if (val) {
      $(this.skusTarget).val("");
      $(this.brandidTarget).val("");
    }
  }
  changeSkus(e) {
    const val = $(e.target).val();
    if (val) {
      $(this.tagidTarget).val("");
      $(this.brandidTarget).val("");
    }
  }
}
