import { Controller } from "@hotwired/stimulus";
import Quill from "quill";
function initEditor(editor, limit) {
  var el = null;
  if (limit == null) {
    el = $(editor);
  } else {
    el = $(editor).get(0);
  }
  if (el) {
    $(el).each(function (i, obj) {
      var quill = new Quill(obj, {
        theme: "snow",
      });
      quill.on("text-change", function () {
        let justHtml = quill.root.innerHTML;
        var inputValue = $(obj).parent().find('input[type="hidden"');
        if (inputValue) {
          $(inputValue).val(justHtml);
        }
      });
    });
  }
}
export default class extends Controller {
  static targets = ["editor"];
  connect() {
    var editor = this.editorTarget;
    initEditor(editor, null);
  }
}
