// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "./src/jquery";
import "./src/argon-dashboard";
import "./src/bootstrap-treefy";
import "./src/jquery-ui";
import { DataTable } from "simple-datatables";
var dataTable = null;
function init() {
  $(".collaptable").treeFy({
    treeColumn: 0,
  });
  if ($(".simple-table").length) {
    dataTable = new DataTable(".simple-table");
  }
  setTimeout(function () {
    $("#flash").slideUp();
  }, 3000);

  $("input.published").on("change", function () {
    $.ajax({
      url: "/dashboard/products/publish_toggle/" + $(this).val(),
      type: "GET",
      success: function (data) {
        console.log(data);
      },
      error: function (error) {},
    });
  });

  if (document.querySelector(".dselect")) {
    dselect(document.querySelector(".dselect"), {
      search: true,
    });
  }
}
$(function () {
  init();
});

document.addEventListener("turbo:render", function () {
  init();
});

document.addEventListener("turbo:before-fetch-response", function () {});

document.addEventListener("turbo:before-cache", function () {
  if (dataTable !== null) {
    dataTable.destroy();
    dataTable = null;
  }
});
