import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    addGroupUri: String,
    updatePositionUri: String,
  };
  connect() {
    const dropzoneId = "#component-dropzone";
    var addGroupUri = this.addGroupUriValue;
    var updatePositionUri = this.updatePositionUriValue;
    $(".drag").draggable({
      appendTo: "body",
      helper: "clone",
    });

    $(dropzoneId)
      .droppable({
        activeClass: "active-item",
        hoverClass: "hover",
        accept: ":not(.ui-sortable-helper)",
        drop: function (e, ui) {
          console.log("ui.draggable ", ui.draggable.find("a").data("type"));
          var $el = $('<turbo-frame class="drop-item">loading..</turbo-frame>');
          $(this).append($el);
          $el.attr("data-id", $el.index());
          $.ajax({
            url: addGroupUri,
            type: "POST",
            data: {
              sort_id: $el.index(),
              group_type: ui.draggable.find("a").data("type"),
            },
            success: function (html) {
              const id = $(html).first().data("id");
              $el.attr("data-id", id);
              $el.attr("id", "home_setting_" + id);
              $el.html(html);
            },
          });
        },
      })
      .sortable({
        items: ".drop-item",
        sort: function () {
          $(this).removeClass("active-item");
        },
      });

    $(dropzoneId).on("sortstop", function (event, ui) {
      var idsInOrder = $(dropzoneId).sortable("toArray", {
        attribute: "data-id",
      });
      $.ajax({
        url: updatePositionUri,
        type: "POST",
        data: {
          ids: idsInOrder,
        },
        success: function (html) {},
      });
    });
    $(document).on("keyup", ".group-name", function (event) {
      $(this).parent().parent().find(".group-title").text($(this).val());
    });
  }
}
