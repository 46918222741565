import { Controller } from "@hotwired/stimulus";
function addDeleteHandler() {
  $(".delete-attribute").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    var parent = $(this).closest("form");
    var url = $(this).attr("href");
    if (url) {
      $.ajax({
        url: url,
        type: "DELETE",
        success: function (data) {
          if (parent) {
            parent.remove();
          }
        },
        error: function (error) {},
      });
    } else {
      if (parent) {
        parent.remove();
      }
    }
  });
}
export default class extends Controller {
  static values = {
    addUri: String,
  };
  static targets = ["addattribute", "attributes"];
  connect() {
    super.connect();
    var addUri = this.addUriValue;
    var attributes = this.attributesTarget;
    addDeleteHandler();
    $(this.addattributeTarget).on("click", function (e) {
      $.ajax({
        url: addUri,
        type: "GET",
        success: function (html) {
          $(attributes).prepend(html);
          addDeleteHandler();
        },
      });
    });
  }
}
