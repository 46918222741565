import { Controller } from "@hotwired/stimulus";
import Quill from "quill";
function initEditor(builders, limit) {
  var el = null;
  var clazz = ".tabbed-content-value";
  if (limit == null) {
    el = $(builders).find(clazz);
  } else {
    el = $(builders).find(clazz).get(0);
  }
  if (el) {
    $(el).each(function (i, obj) {
      var quill = new Quill(obj, {
        theme: "snow",
      });
      quill.on("text-change", function () {
        let justHtml = quill.root.innerHTML;
        var inputValue = $(obj).parent().find('input[type="hidden"');
        if (inputValue) {
          $(inputValue).val(justHtml);
        }
      });
    });
  }
}
function addDeleteHandler() {
  $(".delete-builder").on("click", function (e) {
    e.preventDefault();
    var parent = $(this).closest("form");
    var url = $(this).attr("href");
    if (url !== "#") {
      $.ajax({
        url: url,
        type: "DELETE",
        success: function (data) {
          if (parent) {
            parent.remove();
          }
        },
        error: function (error) {},
      });
    } else {
      if (parent) {
        parent.remove();
      }
      return true;
    }
  });
}
export default class extends Controller {
  static values = {
    addUri: String,
  };
  static targets = ["addbuilder", "builders", "editor"];
  connect() {
    super.connect();
    var builders = this.buildersTarget;
    var addUri = this.addUriValue;
    initEditor(builders, null);
    addDeleteHandler();
    $(this.addbuilderTarget).on("click", function (e) {
      $.ajax({
        url: addUri,
        type: "GET",
        success: function (html) {
          $(builders).prepend(html);
          initEditor(builders, 0);
          addDeleteHandler();
        },
      });
    });
  }
}
