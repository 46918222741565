import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

function deleteImage(removeLink) {
  if (removeLink) {
    var parent = $(removeLink).closest(".dz-preview");
    $.ajax({
      url: $(removeLink).attr("href"),
      type: "DELETE",
      success: function (data) {
        if (parent) {
          parent.remove();
        }
      },
      error: function (error) {},
    });
  }
}

export default class extends Controller {
  static values = {
    clickable: Boolean,
    multiple: Boolean,
  };
  static classes = ["inactive"];
  static targets = ["dropzone"];

  connect() {
    super.connect();
    this.dropzone = new Dropzone(this.dropzoneTarget, {
      clickable: this.clickableValue,
      uploadMultiple: this.multipleValue,
      acceptedFiles: "image/*",
      addRemoveLinks: true,
      init: function () {
        this.on("success", function (file, response) {
          var removeLink = file._removeLink;
          $(removeLink).removeAttr("data-dz-remove");
          $(removeLink).attr("data-turbo-method", "delete");
          $(removeLink).attr("href", response.url);
        });
      },
      removedfile: function (file) {
        deleteImage(file._removeLink);
      },
    });

    $(document).on("click", ".dz-remove", function (e) {
      e.preventDefault();
      e.stopPropagation();
      deleteImage($(this));
    });
  }

  disconnect() {
    //this.dropzone.destroy();
  }

  activate(e) {
    // code to activate dropzone overlay
  }

  deactivate(e) {
    // code to deactivate dropzone overlay
  }
}
