import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["newmodal", "updatemodal", "startat", "endat"];
  connect() {
    $(this.newmodalTarget).on("hidden.bs.modal", function () {
      $(this).find("textarea").val("");
    });
    $(this.updatemodalTarget).on("hidden.bs.modal", function () {
      $(this).find("input[type=text]").val("");
      $(this).find("input[type=number]").val("");
      $(this).find("input[type=datetime-local]").val("");
    });
    const setMinToEndAt = (val) => {
      $(this.endatTarget).attr("min", val);
    };
    $(this.startatTarget).on("change", function () {
      setMinToEndAt($(this).val());
    });
    const setMaxToStartAt = (val) => {
      $(this.startatTarget).attr("max", val);
    };
    $(this.endatTarget).on("change", function () {
      setMaxToStartAt($(this).val());
    });
  }
}
